import React from "react";
import waveSvg from "../assets/wave.svg";
import classNames from "classnames";

const Wave = ({ className }) => (
  <div className="relative w-screen overflow-hidden">
    <div
      className={classNames("wave text-primary", className)}
      data-test="tre"
      data-x={`url(${waveSvg}) repeat-x`}
      style={{
        background: `url(${waveSvg}) repeat-x`,
        color: "blue",
      }}
    />
  </div>
);

export default Wave;
