import Features from "../modules/Features";
import Header from "../modules/Header";
import React from "react";

const Contact = React.lazy(() => import("../modules/Contact"));
const Footer = React.lazy(() => import("../modules/Footer"));
const Stats = React.lazy(() => import("../modules/Stats"));

const Home = () => {
  return (
    <>
      <Header />

      <div className="md:-mt-10 md:pt-10 bg-primary-dark">
        <Features />
      </div>

      <Stats />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
