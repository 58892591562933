import React, { useState } from "react";

import ButtonLink from "../components/ButtonLink";
import Link from "../components/Link";
import Wave from "../components/Wave";
import cn from "classnames";

const Header = ({
  siteName = "Brydon Creative",
  menuItems = [
    { to: "#what-we-do", text: "What we do" },
    { to: "#metrics", text: "Our metrics" },
    { to: "#contact", text: "Contact" },
  ],
  sectionTitleStart = "Crafting dynamic websites and streamlined solutions",
  sectionTitleEnd = "",
  sectionDescription = "Brydon Creative is your premier partner for comprehensive web development services. We excel in designing intuitive web platforms and empowering businesses with automated tools.",
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="relative overflow-hidden">
        <div
          className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
          aria-hidden="true"
        >
          {/* <div className="relative h-full mx-auto max-w-7xl">
            <PixelGrid className="absolute transform right-full translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" />
            <PixelGrid className="absolute transform left-full -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" />
          </div> */}
        </div>

        <div className="relative pt-6 pb-16 sm:pb-24">
          <div className="px-4 mx-auto max-w-7xl sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="/" className="flex items-center text-primary">
                    <span className="text-lg sr-only text-primary">
                      {siteName}
                    </span>
                    {/* <Icon type="logo" className="w-10 h-10" /> */}
                    <h1 className="text-lg font-semibold">Brydon Creative</h1>
                  </a>
                  <div className="flex items-center -mr-2 md:hidden">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-gray-50 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                      aria-expanded="false"
                      onClick={() => setMobileMenuOpen(true)}
                    >
                      <span className="sr-only">Open main menu</span>
                      <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden ml-auto md:flex md:space-x-10">
                {menuItems.map(({ to, text }) => (
                  <Link key={`desktop-menu-item-${to}-${text}`} to={to}>
                    {text}
                  </Link>
                ))}
              </div>
            </nav>
          </div>

          <div
            className={cn(
              !mobileMenuOpen && "hidden",
              "absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            )}
          >
            <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <a href="/" className="flex items-center text-primary">
                  <span className="text-lg sr-only text-primary">
                    {siteName}
                  </span>
                  {/* <Icon type="logo" className="w-10 h-10" /> */}
                  <h1 className="text-lg font-semibold">Brydon Creative</h1>
                </a>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                {menuItems.map(({ to, text }) => (
                  <Link
                    key={`mobile-menu-item-${to}-${text}`}
                    to={to}
                    className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                  >
                    {text}
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <main className="px-4 mx-auto mt-16 max-w-7xl sm:mt-24">
            <div className="text-left">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">{sectionTitleStart}</span>{" "}
                <span className="block text-primary xl:inline">
                  {sectionTitleEnd}
                </span>
              </h1>
              <p className="max-w-3xl mt-3 mr-auto text-base text-gray-500 sm:text-lg md:mt-8 md:text-xl">
                {sectionDescription}
              </p>
              <div className="mt-5 sm:flex sm:justify-start md:mt-12">
                <ButtonLink primary to="#contact">
                  Let's talk
                </ButtonLink>
              </div>
            </div>
          </main>
        </div>
        <Wave />
      </div>
    </>
  );
};
export default Header;
