import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";

const Link = ({ to, className, children }) => (
  <AnchorLink
    href={to}
    className={className || "font-medium text-gray-500 hover:text-gray-900"}
  >
    {children}
  </AnchorLink>
);

export default Link;
