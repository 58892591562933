import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";
import cn from "classnames";

const ButtonLink = ({ to, primary, className, children }) => {
  return primary ? (
    <div className={cn(className, "rounded-md shadow w-button")}>
      <AnchorLink
        href={to}
        className="flex items-center justify-center px-8 py-3 text-base font-medium text-white border border-transparent rounded-md cursor-pointer bg-primary hover:bg-primary-dark md:py-4 md:text-lg md:px-10"
      >
        {children}
      </AnchorLink>
    </div>
  ) : (
    <div className={cn(className, "mt-3 rounded-md shadow sm:mt-0 w-button")}>
      <AnchorLink
        href={to}
        className="flex items-center justify-center px-8 py-3 text-base font-medium text-white border border-transparent rounded-md cursor-pointer bg-primary hover:bg-primary-dark md:text-primary md:bg-white md:hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
      >
        {children}
      </AnchorLink>
    </div>
  );
};

export default ButtonLink;
