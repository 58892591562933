import React from "react";

const Features = ({
  features = [
    {
      title: "Design and branding",
      text: "We'll help you create a captivating and customized look that reflects your brand identity from start to finish.",
    },
    {
      title: "Custom development",
      text: "Whether you need enhancements for your current website or a fresh digital presence, our expert developers are here to assist you.",
    },
    {
      title: "Analytics and insights",
      text: "Gain valuable insights into your customers' behavior and optimize your website's performance to drive engagement and conversions.",
    },
    {
      title: "E-commerce solutions",
      text: "As Shopify experts, we'll help you set up products and build a custom theme for a seamless online shopping experience.",
    },
    {
      title: "Streamlined business tools",
      text: "We can bring your ideas to life by building customized tools and systems that automate processes and improve efficiency.",
    },
  ],
}) => (
  <div id="what-we-do">
    <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <p className="mt-2 text-3xl font-extrabold text-gray-200">
          Our services
        </p>
        <p className="mt-4 text-lg text-gray-300">
          Empowering your business through comprehensive solutions
        </p>
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          {features.map(({ title, text }) => (
            <div className="relative" key={`feature-${title}`}>
              <dt>
                <svg
                  className="absolute w-6 h-6 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <p className="text-lg font-medium leading-6 text-gray-200 ml-9">
                  {title}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-300 ml-9">{text}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
);

export default Features;
